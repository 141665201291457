import React from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DonationPage() {
  const navigate = useNavigate();

  const handleCopyCode = async () => {
    const pixCode =
      '00020126810014br.gov.bcb.pix013631aaf599-27f6-4482-a0ad-eff9cd0f10390219Obrigado pelo apoio5204000053039865802BR5924Raul Scheidemantel Wehmu6008Brasilia62090505wkrtw63044F4B';

    try {
      await navigator.clipboard.writeText(pixCode);
      toast.success('Código PIX copiado para a área de transferência!');
    } catch (err) {
      // Método alternativo para dispositivos que não suportam navigator.clipboard
      const textarea = document.createElement('textarea');
      textarea.value = pixCode;
      textarea.style.position = 'fixed'; // Evita rolagem na página
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();

      try {
        document.execCommand('copy');
        toast.success('Código PIX copiado para a área de transferência!');
      } catch (err) {
        toast.error('Não foi possível copiar o código.');
      }

      document.body.removeChild(textarea);
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-800 text-white p-4">
      <h1 className="text-3xl font-bold mb-6">Pix</h1>
      <div className="mb-6">
        <img src="/qr-code.svg" alt="QR Code Pix" className="w-64 h-64" />
      </div>
      <button
        onClick={handleCopyCode}
        className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded mb-4"
      >
        Copiar código
      </button>
      <button
        onClick={() => navigate('/')}
        className="text-blue-400 hover:underline"
      >
        Voltar
      </button>
      <ToastContainer />
    </div>
  );
}

export default DonationPage;

