import React, { useState, useEffect } from 'react';
import zxcvbn from 'zxcvbn';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FiCopy } from 'react-icons/fi';
import { Link } from 'react-router-dom';

function PasswordGenerator() {
  const [password, setPassword] = useState('');
  const [length, setLength] = useState(24);
  const [options, setOptions] = useState({
    uppercase: true,
    lowercase: true,
    numbers: true,
    symbols: true,
  });
  const [crackTime, setCrackTime] = useState('');
  const [crackTimeColor, setCrackTimeColor] = useState('text-blue-500');

  useEffect(() => {
    generatePassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [length, options]);

  const generatePassword = () => {
    const { uppercase, lowercase, numbers, symbols } = options;

    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const symbolChars = '!@#$%^&*()_+~`|}{[]:;?><,./-=';
    let allChars = '';

    if (uppercase) allChars += uppercaseChars;
    if (lowercase) allChars += lowercaseChars;
    if (numbers) allChars += numberChars;
    if (symbols) allChars += symbolChars;

    if (allChars === '') {
      setPassword('');
      setCrackTime('');
      return;
    }

    let generatedPassword = '';
    for (let i = 0; i < length; i++) {
      generatedPassword += allChars.charAt(
        Math.floor(Math.random() * allChars.length)
      );
    }
    setPassword(generatedPassword);

    // Calcular o tempo para quebrar a senha usando zxcvbn
    const evaluation = zxcvbn(generatedPassword);
    const crackTimeSeconds = evaluation.crack_times_seconds.offline_fast_hashing_1e10_per_second;

    const { time, color } = getCrackTimeInfo(crackTimeSeconds);

    setCrackTime(time);
    setCrackTimeColor(color);
  };

  const handleCopyPassword = async () => {
    if (password === '') {
      toast.error('Nenhuma senha para copiar!');
      return;
    }

    try {
      await navigator.clipboard.writeText(password);
      toast.success('Senha copiada para a área de transferência!');
    } catch (err) {
      // Método alternativo para dispositivos que não suportam navigator.clipboard
      const textarea = document.createElement('textarea');
      textarea.value = password;
      textarea.style.position = 'fixed'; // Evita rolagem na página
      document.body.appendChild(textarea);
      textarea.focus();
      textarea.select();

      try {
        document.execCommand('copy');
        toast.success('Senha copiada para a área de transferência!');
      } catch (err) {
        toast.error('Não foi possível copiar a senha.');
      }

      document.body.removeChild(textarea);
    }
  };


  const handleOptionChange = (e) => {
    setOptions({
      ...options,
      [e.target.name]: e.target.checked,
    });
  };

  const getCrackTimeInfo = (seconds) => {
    let time = '';
    let color = '';

    if (seconds < 1) {
      time = 'IMEDIATAMENTE';
      color = 'text-red-500';
    } else if (seconds >= 1 && seconds < 86400) {
      time = formatTime(seconds);
      color = 'text-orange-500';
    } else if (seconds >= 86400 && seconds < 157680000) {
      time = formatTime(seconds);
      color = 'text-yellow-500';
    } else if (seconds >= 157680000 && seconds < 31536000000) {
      time = formatTime(seconds);
      color = 'text-blue-500';
    } else {
      time = formatTime(seconds);
      color = 'text-green-500';
    }

    return { time, color };
  };

  const formatTime = (seconds) => {
    const units = [
      { label: 'anos', seconds: 31536000 },
      { label: 'dias', seconds: 86400 },
      { label: 'horas', seconds: 3600 },
      { label: 'minutos', seconds: 60 },
      { label: 'segundos', seconds: 1 },
    ];

    let timeStr = '';

    for (const unit of units) {
      const quotient = Math.floor(seconds / unit.seconds);
      if (quotient > 0) {
        timeStr += `${quotient} ${unit.label}`;
        break; // Para esta aplicação, paramos na primeira unidade não zero
      }
    }

    return timeStr || 'IMEDIATAMENTE';
  };

  return (
    <div className="min-h-screen flex flex-col justify-center items-center bg-gray-800 text-white">
      <div className="w-full max-w-md p-8">
        <h1 className="text-2xl font-bold mb-6 text-center">
          Gerador de Senhas Seguras
        </h1>
        <div className="mb-4 relative">
          <input
            type="text"
            value={password}
            readOnly
            className="w-full p-3 text-lg bg-gray-700 rounded"
          />
          <button
            onClick={handleCopyPassword}
            className="absolute right-2 top-2 text-white px-3 py-1"
          >
            <FiCopy size={24} />
          </button>
        </div>

        {crackTime && (
          <div className={`mb-4 text-center font-bold ${crackTimeColor}`}>
            <div>Tempo estimado para quebrar a senha:</div>
            <div>{crackTime}</div>
          </div>
        )}

        <div className="mb-4">
          <label className="block mb-2">
            Tamanho da senha: <span>{length}</span>
          </label>
          <input
            type="range"
            min="4"
            max="64"
            value={length}
            onChange={(e) => setLength(parseInt(e.target.value))}
            className="w-full"
          />
        </div>
        <div className="mb-6">
          <label className="block mb-2">Tipos de caracteres:</label>
          <div className="flex flex-col space-y-2">
            <label className="flex items-center">
              <input
                type="checkbox"
                name="uppercase"
                checked={options.uppercase}
                onChange={handleOptionChange}
                className="mr-2"
              />
              Letras Maiúsculas
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="lowercase"
                checked={options.lowercase}
                onChange={handleOptionChange}
                className="mr-2"
              />
              Letras Minúsculas
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="numbers"
                checked={options.numbers}
                onChange={handleOptionChange}
                className="mr-2"
              />
              Números
            </label>
            <label className="flex items-center">
              <input
                type="checkbox"
                name="symbols"
                checked={options.symbols}
                onChange={handleOptionChange}
                className="mr-2"
              />
              Símbolos
            </label>
          </div>
        </div>
      </div>
      <footer className="mb-4">
        <a
          href="https://donation.raulscheidemantel.com"
          className="text-blue-400 hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ajude a manter o site no ar e sem anúncios.
      	</a>
      </footer>
      <ToastContainer />
    </div>
  );
}

export default PasswordGenerator;

